import cx from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { Input } from 'lib/components/Input'

import IconProfileBold from 'lib/icons/IconProfileBold'
import { PhoneInput } from 'lib/components/PhoneInput'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { post } from 'lib/api'
import { AxiosError } from 'axios'

import type { ValidationError } from 'lib/types/errors'
import getError from 'lib/utils/getError'
import { LOGIN } from 'store/actions'
import { useDispatch } from 'react-redux'
import { UserGuide } from 'components/UserGuide'
import { Agreements } from './components/Agreements'

const Register: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isAgreementChecked, setIsAgreementChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<ValidationError[]>([])
  const [showGuide, setShowGuide] = useState(false)

  const navigate = useNavigate()

  const buttonDisabled =
    !name || !phone || phone === '___ ___ ____' || !email || !password

  const onNameChange = (value: string) => {
    setName(value)

    setErrors(errors.filter((error) => error.field !== 'full_name'))
  }

  const onPhoneChange = (value: string) => {
    setPhone(value.replace(/ /g, ''))

    setErrors(errors.filter((error) => error.field !== 'phone_number'))
  }

  const onEmailChange = (value: string) => {
    setEmail(value)

    setErrors(errors.filter((error) => error.field !== 'email'))
  }

  const onPasswordChange = (value: string) => {
    setPassword(value)

    setErrors(errors.filter((error) => error.field !== 'password'))
  }

  const onSubmit = async () => {
    if (buttonDisabled) {
      return
    }

    setLoading(true)
    setErrors([])

    try {
      const { data } = await post('/register', {
        full_name: name,
        phone_number: phone,
        agreement: isAgreementChecked,
        email,
        password,
      })

      window.localStorage.setItem('token', data.token)

      dispatch({
        type: LOGIN,
        payload: data.user,
      })

      if (searchParams.get('capsule')) {
        navigate(
          `/otp?capsule=${searchParams.get('capsule')}&phone_number=${phone}`
        )
      }

      if (searchParams.get('event')) {
        navigate(
          `/otp?event=${searchParams.get('event')}&phone_number=${phone}`
        )
      }
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          errors: ValidationError[]
        }

        setErrors(data.errors)

        if (data.errors.length === 1 && data.errors[0].field === 'agreement') {
          setTimeout(() => {
            ref.current?.scrollTo({
              top: ref.current?.scrollHeight,
              behavior: 'smooth',
            })
          }, 100)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setErrors(errors.filter((error) => error.field !== 'agreement'))
  }, [isAgreementChecked])

  useEffect(() => {
    setTimeout(() => {
      if (!containerRef.current) {
        return
      }

      containerRef.current.style.opacity = '1'
    }, 100)
  }, [containerRef])

  return (
    <>
      {showGuide && <UserGuide onClose={() => setShowGuide(false)} />}

      <div
        className="flex flex-col h-full opacity-0 transition-opacity duration-[250ms]"
        ref={containerRef}
      >
        <div className="py-[32px] px-[24px] overflow-scroll flex-1" ref={ref}>
          <div className="mb-[32px]">
            <div className="text-greyscale-900 text-h3 font-semibold mb-[16px]">
              Hesap oluştur 👩‍💻
            </div>
            <div className="text-greyscale-700 text-h6 font-medium">
              Kaydolmak için lütfen bilgilerinizi aşağıya girin.
            </div>
            {searchParams.get('capsule') && (
              <div
                className="text-[green] text-[15px] font-bold cursor-pointer mt-[16px] underline"
                onClick={() => setShowGuide(true)}
              >
                Kullanım Kılavuzunu izlemek için tıklayın.
              </div>
            )}
          </div>

          <div className="flex flex-col gap-[32px]">
            <div className="flex flex-col gap-[28px]">
              <div className="flex flex-col gap-[24px]">
                <Input
                  value={name}
                  onChange={onNameChange}
                  placeholder="İsim ve soy isim"
                  label="İsim ve soy isim"
                  leftIcon={<IconProfileBold size={20} />}
                  error={getError(errors, 'full_name')}
                />

                <PhoneInput
                  value={phone}
                  onChange={onPhoneChange}
                  error={getError(errors, 'phone_number')}
                />

                <Input
                  value={email}
                  onChange={onEmailChange}
                  placeholder="E-posta adresi"
                  label="E-posta adresi"
                  htmlType="email"
                  error={getError(errors, 'email')}
                />

                <Input
                  value={password}
                  onChange={onPasswordChange}
                  placeholder="Şifre"
                  label="Şifre"
                  htmlType="password"
                  error={getError(errors, 'password')}
                />
              </div>

              <Agreements
                onChange={setIsAgreementChecked}
                error={getError(errors, 'agreement')}
              />
            </div>
          </div>
        </div>

        <div className="p-[24px] pb-[32px] border-t border-t-greyscale-100 gap-[16px] flex flex-col">
          <div
            className={cx(
              'p-[16px] flex justify-center rounded-[100px] text-lg font-semibold relative overflow-hidden',
              {
                'bg-dark-500 text-greyscale-700': buttonDisabled || loading,
                'bg-dark-100 text-white': !buttonDisabled && !loading,
              }
            )}
            onClick={onSubmit}
          >
            {loading && (
              <div className="bg-dark-500 absolute top-0 right-0 bottom-0 left-0 z-10" />
            )}
            {loading && (
              <div className="loading absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] z-[20]">
                <span />
                <span />
                <span />
              </div>
            )}
            Kayıt Ol
          </div>

          {searchParams.get('capsule') && (
            <div>
              <Link
                to={`/login?capsule=${searchParams.get('capsule')}`}
                className="flex items-center justify-center gap-[8px] text-greyscale-900 text-h6 font-medium"
              >
                Zaten bir hesabın var mı?
                <div className="font-bold text-secondary-900">Giriş Yap</div>
              </Link>
            </div>
          )}
          {searchParams.get('event') && (
            <div>
              <Link
                to={`/login?event=${searchParams.get('event')}`}
                className="flex items-center justify-center gap-[8px] text-greyscale-900 text-h6 font-medium"
              >
                Zaten bir hesabın var mı?
                <div className="font-bold text-secondary-900">Giriş Yap</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Register
